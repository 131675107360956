<template>
  <svg width="250" height="250" viewBox="0 0 200 200">
    <g transform="translate(100 100)">
      <path
        transform="translate(-50 -50)"
        class="st0"
        d="M94.4,35.3c0-0.3-0.2-0.6-0.5-0.6c-0.4-0.1-0.8-0.2-1.2-0.2c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.3-0.3
	c0-0.6-0.3-1.1-0.5-1.6c-0.1-0.2-0.1-0.4,0.1-0.6c0.3-0.3,0.6-0.7,0.9-1c0.6-0.7,1-1.5,1.2-2.5c-0.1-0.2-0.1-0.3-0.2-0.4
	c-0.2-0.3-0.2-0.5-0.1-0.8c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.5,0.1-0.9-0.3-1.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.2-0.5-0.1-0.7
	c0.1-0.2,0.2-0.3,0.2-0.5c0.3-0.7,0.2-1.5-0.1-2.2c-0.3-0.6-0.5-1.2-0.4-1.9c0-0.6,0-1.1-0.1-1.7c-0.1-0.6-0.1-1.2-0.1-1.8
	c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.1-0.5-0.3-0.8-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.3,0.1-0.4
	c0.2-0.6,0.1-1.2-0.3-1.7c-0.4-0.5-0.6-1.1-1.1-1.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.6-0.8-0.8c-0.3-0.1-0.5-0.3-0.7-0.5
	c-0.2-0.2-0.5-0.4-0.7-0.5c-0.5-0.2-0.9-0.5-1.3-0.9c-0.2-0.2-0.3-0.3-0.5-0.4c-0.5-0.3-1-0.4-1.6-0.4c-0.5,0-1,0.1-1.5-0.1
	c-0.3-0.9-1-1.2-1.8-1.2c-0.5-0.1-1-0.1-1.5-0.1c-1,0-2,0.1-3,0.1c-0.3,0-0.6,0.1-0.9,0.1c-1.7,0.3-3.3,0.9-5,1.4
	c-0.7,0.2-1.4,0.5-2,0.9c-0.9,0.6-1.8,1.1-2.6,1.7c-0.6,0.4-1.2,0.9-1.7,1.5c-0.5,0.6-1.1,1.1-1.7,1.6c-0.3,0.3-0.7,0.5-1.1,0.6
	c-0.3,0.1-0.6,0.2-1,0.3c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.2-0.4,0.4-0.6,0.7c-0.3,0.3-0.6,0.7-0.8,1.1c-0.3,0.4-0.6,0.7-1.1,1
	c-0.6,0.3-1.2,0.6-1.8,0.9c-0.5,0.3-1,0.6-1.4,1c-0.4,0.4-0.8,0.9-1.1,1.4c-0.3,0.4-0.6,0.7-1,1.1c-0.3,0.3-0.7,0.6-1.2,0.4
	c-0.2-0.1-0.4,0-0.5,0c-0.3,0-0.6,0.3-0.6,0.6C49,22.2,49,22.5,49,22.7c0,0.2,0,0.4,0,0.5c-0.1,0-0.1,0.1-0.1,0.1
	c-0.4,0-0.9,0-1.3,0.2c-0.2,0.1-0.4-0.1-0.4-0.3c0-0.6-0.1-1.1-0.1-1.7c0-0.2-0.1-0.4-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
	c-0.2,0-0.4,0.1-0.5,0.1c-0.3,0.1-0.5,0.2-0.6,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.3,0.4-0.5,0.4c-0.2,0-0.4-0.1-0.5-0.3
	c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.4-0.3-0.7-0.7-1c-0.3-0.2-0.5-0.5-0.7-0.7c-0.5-0.6-0.6-0.5-0.6-1.6c0-0.4-0.1-0.8-0.3-1
	c-0.5-0.5-0.7-1.2-0.8-1.8c-0.1-0.3-0.2-0.7-0.2-1c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.2-0.4-0.4-0.6-0.4
	c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.4-0.2-0.7-0.3-1.1
	c-0.1-0.4-0.4-0.6-0.8-0.7c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.7-0.2-0.8-0.5-0.7-1.1c0-0.1,0-0.1,0-0.2
	c0-0.4-0.1-0.7-0.3-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.5-0.4-1-0.7-1.6-0.9c-0.2-0.1-0.5-0.2-0.7-0.3c-0.8-0.2-1.4-0.6-1.9-1.2
	c-0.4-0.5-1-0.8-1.7-0.8c-0.6,0-1.1,0-1.7,0c-1,0-1,0-1.5-0.8C23.2,2,22.5,1.4,21.6,1c-0.4-0.2-0.9-0.2-1.3-0.1
	c-0.7,0.1-1.4,0.3-1.8,0.9c0,0.1-0.1,0.1-0.2,0.2c-0.6,0.7-1,1.4-1.2,2.4c0,0.1,0,0.3-0.1,0.4c0,0.6-0.3,1.2-0.4,1.9
	c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2-0.1-0.4-0.1-0.6c0-0.7-0.3-1.3-0.8-1.8
	c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.2-0.6-0.2c-0.4,0-0.8,0.3-0.9,0.7c-0.2,0.7-0.6,1.3-1.1,1.8c-0.1,0.1-0.2,0.3-0.3,0.5
	c-0.4,0.6-0.7,1.1-1.2,1.6C9.9,8.4,9.8,8.7,9.7,8.9c-0.4,1.1-1,2.1-1.7,3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.3-0.3,0.5-0.4,0.8
	c-0.3,0.4-0.5,0.8-0.8,1.2C6.2,14.8,6,15.4,5.7,16c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.4,0,0.8,0.2,1.2c0.1,0.2,0.1,0.4,0.1,0.5
	c-0.1,0.5,0,1,0.2,1.5c0.1,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.2,0.4c-0.3,0.7-0.4,1.5,0.1,2.2c0.3,0.4,0.4,0.8,0.2,1.3
	c0,0,0,0.1,0,0.1C5.9,25,6,25.7,6.4,26.2c0.1,0.2,0.2,0.4,0.3,0.6C6.8,27.1,7,27.3,7,27.7c0,0.1-0.1,0.2-0.1,0.3
	c-0.4,1.2-0.7,2.4-0.6,3.6c0,0.3,0,0.7,0.1,1c0.3,1.6,0.5,3.3,0.9,4.9c0.2,0.7,0.4,1.4,0.6,2.2c0.1,0.3,0.2,0.6,0.4,0.8
	c0.2,0.3,0.6,0.5,1,0.4c0.3-0.1,0.6-0.2,0.8-0.3c0.1,0,0.3,0,0.4,0c0.3,0.1,0.5,0.3,0.7,0.6c0.1,0.1,0.1,0.3,0.1,0.4
	c0,0.1,0,0.2-0.1,0.3c-0.2,0.6-0.3,1.2-0.3,1.9c0,0.1,0,0.1,0,0.2c-0.1,1,0.2,1.8,0.8,2.6c0.5,0.6,0.9,1.3,1.4,1.9
	c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.9,0.4,1.8,0.4,2.8c0,0.3,0,0.7,0.2,1c0.3,0.6,0.4,1.3,0.4,2c0,0.3,0.2,0.5,0.4,0.7
	c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0c0.2-0.1,0.3-0.3,0.5-0.4c0,0,0.2,0,0.2,0c0.2,0.2,0.5,0.4,0.6,0.8
	c0.3,0.5,0.6,0.9,1.1,1.2c0.1,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.4,0.7,0.6c0.1,0.1,0.3,0.3,0.4,0.4c0.4,0.2,0.7,0.6,0.7,1.1
	c0,0.3,0.2,0.5,0.5,0.7c0.5,0.3,0.9,0.8,1.4,1c0.2,0.1,0.3,0.2,0.4,0.4c0.3,0.5,0.7,0.9,1,1.3c0.3,0.3,0.5,0.6,0.6,1.1
	c0.1,0.4,0.2,0.7,0.4,1.1c0.1,0.3,0.3,0.7,0.4,1c0.2,0.8,0.7,1.3,1.4,1.6c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.2,0.6,0.2,1,0.2
	c0.2,0,0.4,0,0.5-0.1c0.3,0,0.5,0.1,0.6,0.4c0.2,0.6,0.6,1.1,0.8,1.6c0.4,0.8,0.8,1.5,0.9,2.4c0,0.2,0.1,0.3,0.3,0.5
	c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0.2,0.7,0.6,0.8,1c0.1,0.2,0.1,0.4,0.1,0.7c0.1,0.5,0.3,1.1,0.4,1.6c0.1,0.3,0.5,0.6,0.8,0.6
	c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0.2,0.6,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.5,0.3,0.8,0.7,1.1
	c0.2,0.1,0.4,0.2,0.4,0.3c0.3,0.6,0.9,0.8,1.5,0.8c0.3,0,0.5,0.1,0.8,0.1c0.4,0.1,0.8,0.2,1.1,0.5c0.2,0.1,0.3,0.3,0.5,0.5
	c0.7,0.6,1.4,0.9,2.3,0.7c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4-0.1,0.6-0.2c0.8-0.4,1.5-0.9,2.3-1.3c0.2-0.1,0.4-0.3,0.5-0.6
	c0.2-0.6,0.7-0.7,1.2-0.8c0.2,0,0.4,0,0.7,0c0.7,0,1.3-0.1,2-0.3c0.5-0.1,0.9-0.3,1.3-0.7c0.4-0.4,0.7-0.8,0.6-1.4
	c-0.1-0.3,0.1-0.6,0.4-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0,0.5,0,0.8,0.1c0.5,0.1,0.8-0.1,1-0.5c0.1-0.2,0.3-0.4,0.4-0.7
	c0.3-0.4,0.6-0.7,1-0.9c0.2-0.1,0.3-0.1,0.5-0.2c0.6-0.3,1.1-0.5,1.8-0.4c0.5,0.1,0.9-0.1,1.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3
	c0.1-0.4,0.2-0.8,0.2-1.2c0.1-0.6,0.4-1,0.9-1.1c0.3-0.1,0.6-0.2,0.9-0.2c0.3-0.1,0.5-0.1,0.8-0.1c0.2-0.1,0.4-0.1,0.6-0.3
	c0.5-0.3,0.9-0.7,1.4-1.1c0.5-0.4,0.9-0.8,0.6-1.5c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.4-0.3
	c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.5,0.1,0.9-0.1,1.3-0.4c0.3-0.3,0.5-0.5,0.8-0.8c0.4-0.4,0.8-0.8,1.3-1
	c0.5-0.2,0.8-0.4,1.2-0.8c0.5-0.6,1.1-1.2,1.9-1.6c0.2-0.1,0.3-0.3,0.3-0.6c0-0.1,0-0.3,0-0.4c-0.1-0.7,0.2-1.3,0.8-1.8
	c0.2-0.2,0.4-0.3,0.6-0.4c0.4-0.2,0.8-0.4,1.1-0.7c0.7-0.5,1.3-1,1.5-1.9c0.1-0.4,0.3-0.7,0.6-0.9c0.5-0.4,1.1-0.8,1.7-0.9
	c0.9-0.2,1.7-0.8,2.6-1c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.4,0.1-0.8,0-1.2c-0.1-0.6,0.1-1,0.4-1.5
	c0.2-0.3,0.4-0.5,0.7-0.7c0.2-0.2,0.5-0.4,0.8-0.6c0.9-0.7,0.9-0.7,1.1-1.8c0-0.1,0-0.1,0-0.2c0.1-0.5,0.3-1,0.7-1.4
	c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3,0.1-0.5,0.1-0.8c0-0.4,0.2-0.6,0.4-0.9c0.2-0.2,0.4-0.3,0.6-0.5
	c0.3-0.2,0.6-0.6,0.8-0.9c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.3-0.2-0.7-0.3-1.1c0-0.5,0.1-1,0.4-1.4
	c0.4-0.5,0.8-0.8,1.4-1.1c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.3,0.7-0.5c0.4-0.4,0.9-0.8,1.4-1c0.2-0.1,0.2-0.3,0.3-0.5
	C94.4,35.5,94.4,35.4,94.4,35.3z M11.3,34.5c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.7-0.3,1.2-0.7,1.7c-0.1,0.1-0.3,0.1-0.5,0
	c-0.1-0.1-0.1-0.1-0.2-0.1c-0.5-0.7-0.8-1.5-0.7-2.4c0-0.1,0-0.3,0.1-0.4c0.1-0.3,0.1-0.7,0-1c0-0.3-0.1-0.7-0.1-1
	c-0.1-0.8,0.1-1.5,0.6-2.1c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3,0,0.4,0.1c0.1,0.2,0.1,0.4,0.2,0.7
	c0,0.2,0,0.4,0,0.7c-0.1,0.5,0,1,0.2,1.5C11.4,32.8,11.5,33.6,11.3,34.5z M13.7,12.5C13.7,12.5,13.7,12.6,13.7,12.5
	c-0.3-0.1-0.3-0.4-0.3-0.6c0,0,0.1,0,0.1,0C13.7,12,13.8,12.2,13.7,12.5z M19.2,7.9c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.2,0.1
	c0,0,0-0.1-0.1-0.2C19.1,7.9,19.2,7.8,19.2,7.9z M15.5,41.2c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.3-0.1-0.4
	c0-0.2,0-0.4,0.1-0.6c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.3,0.1,0.4C15.8,40.8,15.7,41,15.5,41.2z M15.8,19
	c-0.1,0.4-0.3,0.8-0.6,1.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0.1-0.6,0.2-1.1,0.6-1.5
	c0.1-0.1,0.2-0.2,0.3-0.4c0.4-0.5,0.7-1.2,0.7-1.9c0-0.3,0-0.6,0.2-0.9c0.3-0.5,0.5-0.5,1-0.1c0.8,0.6,0.8,0.7,0.2,1.6
	c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.6C16.2,18,15.9,18.4,15.8,19z M18.3,37c-0.4,0.3-0.6,0.7-0.8,1.2
	c-0.1,0.2-0.4,0.3-0.5,0.1c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.2-0.5-0.1-0.8c0-0.1,0.1-0.1,0.1-0.2c0.4-0.8,0.8-1.5,1.2-2.3
	c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0,0.2,0.2,0.2,0.4c0,0.1,0,0.2-0.1,0.4c0,0.1,0,0.2,0,0.4
	c0.1,0.5-0.1,1-0.2,1.6C18.7,36.6,18.5,36.8,18.3,37z M19.7,40.1c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.5-0.2,1-0.1,1.4
	c0.1,0.3-0.1,0.4-0.2,0.4c-0.2,0.1-0.5-0.1-0.6-0.3c-0.2-0.5-0.3-0.9-0.3-1.4c0-0.2,0-0.4,0.1-0.5c0-0.2,0.1-0.4,0-0.7
	c-0.1-0.5,0-0.9,0.2-1.3c0.2-0.3,0.2-0.6,0.5-0.9c0,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0,0.2c-0.1,0.3,0,0.5,0.2,0.7
	C19.7,39.5,19.8,39.8,19.7,40.1z M24.1,11.3c0.1,0,0.3,0,0.4,0c0.4,0,0.4,0,0.6-0.4c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.4-0.1,0.7-0.3,1.1c0,0.1-0.2,0.2-0.3,0.1c-0.4-0.1-0.8-0.4-1-0.8C23.9,11.4,24,11.3,24.1,11.3
	z M21.2,17.9c0-0.3,0-0.5,0.1-0.8c0.3-0.6,0.3-1.3,0.6-1.9C22,15,22,14.8,22,14.7c0.1-0.4,0.3-0.5,0.7-0.5c0.1,0,0.2,0,0.2,0.1
	c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.4-0.3,0.7-0.3,1.2c0.1,0.5,0.1,1,0.2,1.4c0,0.3,0.1,0.5,0.1,0.8
	c0,0.4-0.2,0.6-0.5,0.8c-0.3,0.1-0.5,0.1-0.7-0.1C21.4,18.8,21.2,18.4,21.2,17.9z M22.4,50.6c-0.2,0.2-0.5,0.3-0.9,0.3
	c-0.3-0.1-0.7-0.2-1-0.4c0,0-0.1,0-0.1-0.1c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.3,0,0.4,0
	c0.6,0.2,1.1,0.6,1.6,1c0.1,0,0.1,0.1,0.1,0.2C22.5,50.4,22.4,50.6,22.4,50.6z M25.9,44.4c-0.4-0.4-0.8-0.5-1.3-0.6
	c-0.3-0.1-0.6-0.1-0.9-0.2c-0.4-0.1-0.7-0.3-0.7-0.7c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.2-0.5-0.5-0.6c-0.2-0.1-0.3-0.2-0.5-0.3
	c-0.2-0.1-0.3-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.7c0.1-0.4,0.1-0.7-0.2-1.1c-0.3-0.4-0.3-0.8-0.1-1.3c0.1-0.3,0.3-0.6,0.4-0.9
	c0.2-0.3,0.3-0.7,0.6-0.9c0.3-0.3,0.5-0.6,0.7-1c0.2-0.4,0.2-0.8-0.2-1.1c0,0-0.1-0.1-0.1-0.1c-0.7-0.5-0.6-0.6-0.2-1.3
	c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.2,0.9-0.2,1.4,0c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.2,0.7,0.1
	c0.4-0.1,0.9-0.1,1.3-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.2,0.2,0.3c-0.2,0.4-0.5,0.7-0.6,1.2c-0.1,0.5,0.2,0.8,0.6,0.9
	c0.2,0,0.4,0,0.7,0c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.6,0.5-0.9,0.6c-0.4,0.2-0.7,0.4-0.9,0.8
	c-0.4,0.5-0.5,1.1-0.2,1.7c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.1,0.4,0,0.5c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.1,0.1,0.2
	c0.1,0.3,0.1,0.6-0.2,0.8c-0.2,0.2-0.4,0.5-0.5,0.7c-0.3,0.6,0,1.3,0.5,1.5c0.3,0.1,0.5,0.2,0.8,0.3c-0.1,0.3-0.4,0.5-0.6,0.6
	C26.5,44.6,26.2,44.7,25.9,44.4z M28.3,52.6c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.3-0.1-0.5-0.1-0.8
	c0-0.1,0-0.3,0.2-0.4c0,0,0.2,0,0.2,0C28.4,51.8,28.4,52.2,28.3,52.6z M29.2,65.9c-0.1,0.1-0.2,0-0.3,0c-0.3-0.2-0.6-0.4-0.8-0.5
	c-0.4-0.3-0.6-0.6-0.8-1c-0.2-0.6-0.3-1.1-0.2-1.8c0-0.1,0.1-0.2,0.2-0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.5,0.2,0.7,0.6,0.9,1.1
	c0.2,0.7,0.4,1.4,0.5,2C29.2,65.8,29.3,65.9,29.2,65.9z M30.4,53.9c0,0.3-0.1,0.6-0.3,0.8c-0.6,0.9-0.9,1.9-1.1,2.9
	c0,0.2-0.1,0.4-0.2,0.5c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.2,0-0.4,0-0.6
	c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.2-0.2c0.7-0.4,1-1.2,1.2-1.9c0.1-0.3,0.1-0.7,0.2-1c0,0,0.1-0.1,0.2-0.1
	c0.1,0,0.1,0.1,0.2,0.1c0,0.2,0.1,0.4,0.1,0.6C30.5,53.1,30.4,53.5,30.4,53.9z M31.4,22.1c-0.2,0.2-0.3,0.3-0.6,0.3
	c-0.6,0.1-1,0.4-1.5,0.6c-0.5,0.2-0.9,0.6-1.2,1c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.1-0.3-0.2-0.4-0.4
	c-0.5-0.5-1-0.7-1.7-0.6c-0.3,0-0.6,0-1-0.2c-0.3-0.2-0.6-0.2-1-0.2c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.4,0.1-0.5
	c0.3-0.2,0.6-0.3,0.9-0.2c0.6,0.1,1.3,0.2,1.9,0.3c0.2,0,0.5,0.2,0.7,0.3c0.3,0.1,0.6,0.2,1,0.3c0.3,0,0.6,0,0.8-0.3
	c0.2-0.2,0.3-0.3,0.5-0.5c0.5-0.5,1.2-0.8,1.9-0.9c0.2,0,0.4,0,0.5,0.1C31.6,21.4,31.6,21.8,31.4,22.1z M36.9,23.7
	c0.2-0.2,0.4-0.4,0.7-0.6c0.4-0.2,0.7-0.6,1-1c0.2-0.4,0.3-0.8,0-1.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.3,0-0.4
	c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.4,0,0.7,0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.2,0.1,0.3,0.1
	c0.2,0.2,0.3,0.4,0.5,0.6c0.4,0.3,0.6,0.7,0.7,1.1c0,0.3,0.2,0.5,0.5,0.7c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.2,0.1
	c-0.5,0.2-1,0.4-1.5,0.5c-1.1,0.3-2.1,0.5-3.2,0.9c-0.1,0.1-0.3,0.1-0.4,0.1C37,23.9,37,23.8,36.9,23.7
	C36.9,23.8,36.9,23.7,36.9,23.7z M35.6,21.7c0.1-0.1,0.3-0.1,0.4,0c0.2,0,0.3,0.2,0.2,0.4c-0.1,0.3-0.3,0.5-0.6,0.6c0,0-0.1,0-0.1,0
	c0,0-0.1-0.1-0.1-0.1C35.3,22.1,35.4,21.9,35.6,21.7z M33.1,48.4c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
	c0-0.1,0.1-0.1,0.2-0.2C33.1,48.2,33.2,48.3,33.1,48.4z M35,64.7c0,0-0.1,0-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3,0.1-0.4
	c0.4-0.3,0.8-0.5,1.2-0.8c0,0,0.1,0.1,0.1,0.1C35.7,63.9,35.4,64.3,35,64.7z M38,48c-0.2-0.1-0.4-0.2-0.6-0.3
	c-0.7-0.3-1.3-0.2-2,0.3c-0.4,0.3-0.6,0.8-1,1.2c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0.1-0.3,0-0.5-0.1-0.8
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.5-0.2-0.8c0-0.1,0-0.1,0-0.2c0-1,0-1,0.9-1.6c0.2-0.2,0.5-0.3,0.8-0.5
	c0.3-0.2,0.6-0.4,0.8-0.8c0.1-0.2,0.4-0.3,0.7-0.2c0,0,0.1,0,0.1,0c0.7,0.4,1.4,0.5,2.1,0.5c0.1,0,0.3,0,0.4,0.1
	c0.3,0.1,0.4,0.6,0.2,0.8c-0.1,0.1-0.2,0.2-0.2,0.2c-0.5,0.3-0.6,0.8-0.5,1.4c0,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3-0.1,0.4
	c-0.1,0.2-0.3,0.4-0.5,0.3C38.3,48.1,38.2,48,38,48z M37.4,39.8c-0.1-0.3-0.3-0.5-0.4-0.9c0.1-0.1,0.1-0.2,0.1-0.2
	c0.1-0.2,0.2-0.4,0.1-0.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.3,0-0.4c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.4
	c-0.1-0.2,0-0.4,0.2-0.6c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0-0.5,0.3-0.7,0.6-0.9c0.2-0.1,0.4-0.3,0.6-0.4
	C39.9,34,40,34,40.1,34c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.1,0.6
	c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.4-0.3,0.7-0.4,1.1c0,0.3-0.1,0.7-0.1,1c0,0.2-0.1,0.4-0.3,0.6c-0.3,0.3-0.4,0.8-0.7,1.1
	c0,0-0.1,0.1-0.1,0.2c0.1,0.4-0.2,0.6-0.5,0.8c-0.2,0.1-0.4,0.3-0.7,0.3c-0.4,0.1-0.6,0.4-0.7,0.7c-0.3,0.1-0.6,0.2-1,0.2
	c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0.2-0.1,0.3-0.3,0.5-0.4c0.4-0.3,0.7-0.5,1.1-0.8C37.4,40.4,37.6,40.1,37.4,39.8z M41.8,55.5
	c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.7,0.6-1.2,1.4-1.8,2.1c-0.1,0.1-0.3,0.3-0.4,0.4
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0.2-0.9,0.5-1.8,0.8-2.7c0.1-0.3,0.2-0.5,0.2-0.8c0-0.5,0.3-0.9,0.5-1.3
	c0,0,0.1-0.1,0.2,0c0.1,0,0.1,0,0.2,0.1c0.4,0.4,0.8,0.6,1.4,0.7C41.9,55.2,41.9,55.4,41.8,55.5z M42,28.6c-0.4,0.1-0.9,0.1-1.3,0.2
	c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.2-0.7,0.5-1,0.7c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.2-0.8,0.3-1.2,0.1c-0.3-0.1-0.6-0.1-1-0.1
	c-0.3,0-0.6,0.2-0.8,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.8,0.6-1.3,0.5c-0.2,0-0.4-0.1-0.5-0.4c-0.1-0.3-0.3-0.7-0.4-1
	c-0.1-0.4,0.1-0.9,0.5-1.1c0.3-0.2,0.7-0.2,1.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.1,0.8,0.1,1.2-0.1c0.7-0.5,1.4-0.6,2.1-0.8
	c0.6-0.2,1.1-0.3,1.7-0.1c0.7,0.2,1.3,0.1,1.9-0.2c0.1,0,0.3-0.1,0.4,0c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
	C42.5,28.4,42.2,28.5,42,28.6z M44.1,50.4c0,0.2-0.2,0.3-0.3,0.4c-0.4,0.4-0.7,0.8-0.8,1.3c-0.1,0.2-0.1,0.4-0.3,0.6
	c-0.1,0.1-0.2,0-0.3-0.1c-0.1-0.2-0.1-0.4,0-0.5c0.4-0.4,0.6-0.8,0.8-1.3c0.1-0.4,0.4-0.5,0.7-0.6c0.1,0,0.1,0.1,0.2,0.1
	C44.1,50.3,44.1,50.4,44.1,50.4z M44.4,52.1L44.4,52.1L44.4,52.1C44.4,52.1,44.4,52.1,44.4,52.1z M46.6,41.3c0,0.1-0.1,0.2-0.2,0.3
	c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.4-0.2-0.6,0.3-0.9c0.1,0,0.2,0,0.3,0.1C46.6,40.6,46.6,41,46.6,41.3z
    M47.1,27.5c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0,0,0.2,0,0.2,0c0.3,0.2,0.6,0.4,0.9,0.6c0,0,0,0.1,0,0.2
	c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.1-0.4,0.1-0.6,0C47.4,27.8,47.3,27.7,47.1,27.5z M49,39.2c-0.3,0.4-0.7,0.5-1.1,0.7
	c-0.1,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.5-0.3,0.7-0.5c0.4-0.3,0.4-0.3,0.2-0.8
	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2,0.1-0.4,0.4-0.4c0.1,0,0.1,0,0.1,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.5
	C49.4,38.6,49.3,38.9,49,39.2z M54,31.9c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.1,0.6-0.2
	c0.3-0.1,0.4,0.1,0.4,0.2C55.1,32,55,32.3,55,32.7c0,0.2-0.2,0.3-0.4,0.2c-0.1-0.1-0.3-0.2-0.3-0.3C54.1,32.4,54,32.1,54,31.9z
	M56,56.4c0,0.1,0,0.2-0.1,0.3c-0.3,0.4-0.6,0.7-0.9,1.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.3,0.1c-0.2-0.1-0.4-0.1-0.6-0.2
	c0,0-0.1-0.1-0.1-0.2c0.1-0.4,0.4-0.7,0.7-1c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.4,0,0.6-0.1C55.8,56.3,56,56.4,56,56.4z M74.5,35
	c0,0,0.1,0,0.1,0c0.2,0.2,0.4,0.4,0.5,0.7c0,0-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0c-0.2-0.2-0.4-0.4-0.5-0.7C74.4,35.1,74.5,35,74.5,35
	z M60.8,39.1c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2C60.8,39.2,60.8,39.1,60.8,39.1z M57.7,53.9
	c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.3-0.1-0.5-0.1-0.8c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1C57.8,53.5,57.8,53.7,57.7,53.9z
	M59.8,41.2c-0.4,0.2-0.6,0.5-0.8,0.9c0,0.1-0.1,0.3-0.2,0.4c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.6-0.3-1-0.3
	c-0.1,0-0.2,0-0.3,0c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.4,0-0.6,0.3-0.8c0.1-0.1,0.3-0.1,0.4-0.1c0.4-0.1,0.7-0.3,0.8-0.8
	c0,0,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.5,0.2,0.9,0.4,1.4,0.7c0,0,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1C60,41,59.9,41.1,59.8,41.2z
	M60.3,60.5c0,0.1-0.1,0.1-0.1,0.3c-0.1-0.2-0.2-0.4-0.3-0.5c0,0,0-0.1,0-0.1c0.1,0,0.1-0.1,0.2-0.1C60.3,60.1,60.4,60.3,60.3,60.5z
	M61.7,60.4C61.7,60.4,61.6,60.4,61.7,60.4c-0.4,0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1-0.2,0-0.3c0-0.1,0.3-0.2,0.4-0.1
	c0.2,0.2,0.4,0.3,0.5,0.5C61.7,60.3,61.7,60.3,61.7,60.4z M63.3,43.7c-0.1,0.6-0.2,1.1-0.3,1.7c0,0.2-0.2,0.3-0.4,0.3
	c-0.3,0-0.6-0.1-0.9-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.3-0.5-0.7-0.9-1.2-1.1c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.5,0.3-0.9,0.6-1.3
	c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.1,0.5,0c0.3,0.2,0.5,0.4,0.9,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0.2,0.3,0.3
	C63.3,43,63.4,43.4,63.3,43.7z M62.9,25c-0.1-0.4-0.3-0.7-0.7-0.8c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1,0-0.2
	c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0-0.5c-0.3-0.4-0.3-0.8-0.3-1.2c0.7-0.7,1-0.9,1.9-1.2c0.3-0.1,0.5-0.2,0.8-0.3
	c0.3-0.1,0.6-0.2,0.8-0.2c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.5,0.5,0.7,1,0.7c0.4,0.1,0.6,0.5,0.5,0.8
	c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.4-0.4,0.9,0,1.3c0.1,0.1,0.1,0.3,0.1,0.5c-0.2,0.4-0.4,0.9-0.7,1.3
	c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0.1-0.5,0c0,0,0,0-0.1-0.1c-0.2-0.4-0.4-0.7-0.9-0.7
	C63.2,25.4,63,25.3,62.9,25z M66.5,48.2c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1,0-0.3,0.1-0.4c0.1-0.5,0-0.9-0.5-1.2c-0.3-0.2-0.3-0.6-0.1-0.8c0.6-0.9,0.8-0.9,1.4-0.3c0.3,0.3,0.3,0.5,0.1,0.8
	c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.2,0.4-0.2,0.6C66.5,47.6,66.5,47.9,66.5,48.2z M68.2,54.5c-0.5,1-1,2-1.6,2.9
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.7-0.2-1-0.1-1.1,0.7c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.2,0.2-0.3,0.1c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.2,0-0.3,0.1-0.5c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.4,0.4-0.5c0.5-0.3,0.6-0.9,1.1-1.2
	c0.1,0,0.1-0.1,0.1-0.2c0.4-1.1,0.8-2.2,1.3-3.2c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.3,0.4,0.6,0.3,1
	c0,0.2,0,0.4-0.1,0.6c-0.1,0.3,0,0.7,0.2,1C68.3,54,68.3,54.2,68.2,54.5z M68,31.5c-0.1,0.1-0.2,0-0.3-0.1c-0.1-0.4-0.2-0.9-0.3-1.3
	c-0.2-0.6-0.1-1.1,0.2-1.6c0.1-0.2,0.3-0.3,0.5-0.5c0,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1c0,0.2-0.1,0.4-0.1,0.6
	c0,0.5-0.1,1.1,0.1,1.7C68.5,30.9,68.3,31.3,68,31.5z M72.1,16c-1,0.2-1.1,0.1-1.5,1.2c-0.1,0.3-0.4,0.6-0.6,0.7c-0.1,0-0.2,0-0.3,0
	c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.5-0.5-1.1-0.6-1.6c0-0.4,0.2-0.7,0.4-0.7c0.1,0,0.3,0,0.4-0.1c0.3,0,0.5,0,0.8,0
	c0.5-0.1,0.8-0.3,0.9-0.8c0.1-0.6,0.5-1.1,0.9-1.5c0.1-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.3,0,0.3,0c0,0.1,0,0.2,0,0.2
	c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.4-0.3,0.7-0.2,1.2c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.4-0.3,0.5C72.2,16,72.2,16,72.1,16z
	M74.8,40.7c-0.3,0.1-0.7,0.3-1,0.4c-0.2-0.6-0.3-0.8-0.7-1.2c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.3-0.3-0.6-0.2-0.9
	c0.1-0.5,0.4-0.9,0.8-1.1c0.5-0.2,1-0.3,1.5-0.4c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.5,0.1,1,0.5,1.3c0.2,0.2,0.3,0.4,0.3,0.7C75.2,40.4,75.1,40.6,74.8,40.7z M80.7,51.7
	c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.8-0.8,1.4-1.6,1.5c-1.1,0.2-2,0.6-3,1.1c-0.1,0-0.2,0-0.2,0
	c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.3,0.1-0.4c0.1-0.3,0.3-0.5,0.5-0.8c0.1-0.1,0.2-0.2,0.2-0.2c0.5-0.1,0.7-0.5,1-0.9
	c0.4-0.5,1-1,1.5-1.5c0.1-0.1,0.2-0.2,0.4-0.3c0.4,0,0.7,0.2,1.1,0.1c0.2,0,0.3,0.1,0.5,0.3C80.7,51.6,80.7,51.7,80.7,51.7z
	M78.5,36.9c0.1-0.1,0.2-0.1,0.3,0c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.2-0.1C78.4,37.1,78.4,37,78.5,36.9z
	M81.3,35.3c0,0.1,0,0.1-0.1,0.2c0,0.6-0.4,0.9-0.9,1.1c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.4-0.4-0.9-0.6-1.3c-0.2-0.4-0.4-0.8-0.9-1
	c-0.3-0.2-0.6-0.4-0.9-0.7c0,0,0-0.1,0-0.2c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1,0c0.9,0.1,1.6-0.3,2.2-0.8c0.2-0.2,0.4-0.4,0.6-0.5
	c0.1,0,0.3,0.1,0.4,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.1,0.7-0.2,1c0,0.4,0,0.7,0.3,1c0.1,0.1,0.1,0.2,0.2,0.3
	C81.3,34.9,81.4,35.1,81.3,35.3z M78.2,30.8c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0-0.4,0-0.8,0.4-1
	C78.4,30.1,78.4,30.5,78.2,30.8z M78.4,29.7c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.1,0,0.2
	C78.8,29.3,78.6,29.5,78.4,29.7z M79.7,23c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.1,0c0,0,0,0.1,0.1,0.1c0,0.2,0,0.3-0.1,0.4
	c0,0-0.1,0-0.1,0C79.7,23.2,79.7,23.1,79.7,23z M83.6,17.5c-0.3,0.1-0.5,0.2-0.8,0.3c-0.4,0.1-0.7,0.4-0.9,0.7
	c-0.3,0.4-0.5,0.9-0.6,1.4c-0.1,0.2-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.2-0.2-0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.4
	c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.3,0.1-0.6-0.1-0.8c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.3-0.4
	c0-0.1,0-0.2,0-0.3c0-0.2-0.2-0.4-0.4-0.5c-0.3-0.1-0.7-0.2-1-0.5c-0.1-0.1-0.2-0.4,0-0.5c0.3-0.2,0.5-0.4,0.9-0.4
	c0.3,0,0.5,0,0.7-0.2c0.3-0.3,0.7-0.5,1.1-0.6c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.6,0.2c0.2,0.1,0.3,0.3,0.5,0.4
	c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c-0.1,0.7,0.3,1.1,0.8,1.5c0.2,0.1,0.3,0.2,0.4,0.4c0,0,0,0.1,0.1,0.2
	C83.7,17.4,83.7,17.5,83.6,17.5z M91.5,35.8c0,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.1
	C91.5,35.6,91.5,35.7,91.5,35.8z"
      />
      <animateTransform
        attributeName="transform"
        type="scale"
        values="1; 1.5; 1.25; 1.5; 1.5; 1;"
        dur="2.0s"
        repeatCount="indefinite"
        additive="sum"
      ></animateTransform>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BeatingHeart",
};
</script>

 <style type="text/css" scoped>
.st0 {
  fill: #e80c56;
}
</style>
